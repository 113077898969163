<template>
  <el-dialog
      title="新增数据项"
      :close-on-click-modal="true"
      :visible.sync="addMeasurePointDialog"
      v-if="addMeasurePointDialog"
      @close="closeDialog"
      width="80%"
      append-to-body>

    <el-steps :space="200" :active="activeIndex-0" finish-status="success" align-center>
      <el-step title="所属附属设备"></el-step>
      <el-step title="填写数据项信息"></el-step>
<!--      <el-step title="填写数据质量信息"></el-step>-->
      <el-step title="确认"></el-step>
    </el-steps>

    <el-form :label-position="'top'" label-width="100%" :rules="rules" ref="addForm" :model="addForm">
      <el-tabs :tab-position="'left'" v-model="activeIndex">
        <el-tab-pane label="所属附属设备" name="0">
          <el-form-item label="附属设备名称" prop="parentList">
<!--            <el-cascader-->
<!--                v-model="addForm.parentList"-->
<!--                :options="options"-->
<!--                separator=" | "-->
<!--                style="width: 50%;"-->
<!--                :props="props"-->
<!--                placeholder="请选择附属设备"-->
<!--                clearable-->
<!--                ref="cascade"-->
<!--            ></el-cascader>-->
            <el-input v-model="global.coal_name + ' | ' + global.system_name + ' | ' + global.subSystem_name + ' | ' + global.equipment_name + ' | ' + global.sub_equipment_name" readonly="true" style="width: 500px"></el-input>
          </el-form-item>
        </el-tab-pane>

        <el-tab-pane label="填写数据项信息" name="1">
          <el-form-item label="数据项名称" prop="measurePointName">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="addForm.measurePointName" placeholder="请输入数据项名称"></el-input>
          </el-form-item>
          <el-form-item label="数据项数据类型" prop="type">
            <el-select v-model="addForm.type" placeholder="请选择">
              <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="描述" prop="remark">
            <el-input style="width: 45%;margin-bottom: 5px" v-model="addForm.remark" placeholder="添加数据项描述" type="textarea"></el-input>
          </el-form-item>
        </el-tab-pane>

<!--        <el-tab-pane label="填写数据质量信息" name="2">-->
<!--          <el-row :gutter="20">-->
<!--            <el-col :span="12">-->
<!--              &lt;!&ndash; 是否需要数据治理 &ndash;&gt;-->
<!--              <el-form-item label="是否需要数据治理">-->
<!--                <el-col :span="7">-->
<!--                  <el-switch-->
<!--                      v-model="isNeedDataGovernment"-->
<!--                      active-text="需要"-->
<!--                      inactive-text="不需要"-->
<!--                      @click.native="isNeedDataGovernmentChange(isNeedDataGovernment)">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--              </el-form-item>-->
<!--              &lt;!&ndash;数据缺失处理模块&ndash;&gt;-->
<!--              <el-form-item label="数据缺失处理" prop="isExisted">-->
<!--                <el-select v-model="addForm.isExisted" placeholder="是 / 否" style="width: 100px">-->
<!--                  <el-option label="是" value="yes" ></el-option>-->
<!--                  <el-option label="否" value="no"></el-option>-->
<!--                </el-select>-->
<!--                <el-switch-->
<!--                    v-model="isExistedSwitch"-->
<!--                    active-text="必选"-->
<!--                    inactive-text="可选"-->
<!--                >-->
<!--                </el-switch>-->
<!--                <el-select v-model="addForm.isExistedmethod" placeholder="请选择治理函数" size="small" style="margin-left: 30px">-->
<!--                  <el-option-->
<!--                      v-for="item in Methodoptions"-->
<!--                      :key="item.id"-->
<!--                      :label="item.name"-->
<!--                      :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--              &lt;!&ndash;数据类型模块&ndash;&gt;-->
<!--              <el-form-item label="数据类型">-->
<!--                <el-col :span="5">-->
<!--                  <el-switch-->
<!--                      v-model="typeSwitch"-->
<!--                      active-text="必选"-->
<!--                      inactive-text="可选">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--                <el-col :span="5">-->
<!--                  <el-form-item prop="type">-->
<!--                    <el-select v-model="addForm.type" placeholder="请选择类型" size="small" style="margin-left: 30px">-->
<!--                      <el-option-->
<!--                          v-for="item in typeOptions"-->
<!--                          :key="item.id"-->
<!--                          :label="item.name"-->
<!--                          :value="item.name">-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-select v-show="show" v-model="addForm.typemethod"  placeholder="请选择治理函数" size="small" style="margin-left: 70px">-->
<!--                  <el-option-->
<!--                      v-for="item in Methodoptions"-->
<!--                      :key="item.id"-->
<!--                      :label="item.name"-->
<!--                      :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->

<!--              &lt;!&ndash;阈值范围模块&ndash;&gt;-->
<!--              <el-form-item label="阈值范围" prop="range">-->
<!--                <el-col :span="5">-->
<!--                  <el-switch-->
<!--                      v-model="rangeSwitch"-->
<!--                      active-text="必选"-->
<!--                      inactive-text="可选">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--                <el-col :span="3">-->
<!--                  <el-form-item prop="lowrange">-->
<!--                    &lt;!&ndash;required要重新设置一个方法，一是下阈值小于上域值，二是上下阈值有其中一个就够了。&ndash;&gt;-->
<!--                    <el-input v-model="addForm.lowrange" style="width: 100%"></el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col class="line" style="margin-left: 1%;width: auto"> - </el-col>-->
<!--                <el-col :span="3" style="margin-left: 1%">-->
<!--                  <el-form-item prop="uprange">-->
<!--                    &lt;!&ndash;required要重新设置一个方法，一是下阈值小于上域值，二是上下阈值有其中一个就够了。&ndash;&gt;-->
<!--                    <el-input v-model="addForm.uprange" style="width: 100%"></el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--              </el-form-item>-->

<!--              &lt;!&ndash;数据合理范围模块&ndash;&gt;-->
<!--              <el-form-item label="数据合理范围" prop="rationalityrange">-->
<!--                <el-col :span="5">-->
<!--                  <el-switch-->
<!--                      v-model="rationalityrangeSwitch"-->
<!--                      active-text="必选"-->
<!--                      inactive-text="可选">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--                <el-col :span="3">-->
<!--                  <el-form-item prop="rationalitylowrange">-->
<!--                    <el-input v-model="addForm.rationalitylowrange" style="width: 100%;"></el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-col class="line" style="margin-left: 1%;width: auto"> - </el-col>-->
<!--                <el-col :span="3" style="margin-left: 1%">-->
<!--                  <el-form-item prop="rationalityuprange">-->
<!--                    <el-input v-model="addForm.rationalityuprange" style="width: 100%;"></el-input>-->
<!--                  </el-form-item>-->
<!--                </el-col>-->
<!--                <el-select v-model="addForm.rationalitymethod"  placeholder="请选择治理函数" size="small" style="margin-left: 17px">-->
<!--                  <el-option-->
<!--                      v-for="item in Methodoptions"-->
<!--                      :key="item.id"-->
<!--                      :label="item.name"-->
<!--                      :value="item.id">-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
<!--            <el-col :span="12">-->
<!--              &lt;!&ndash;数据存储长度模块&ndash;&gt;-->
<!--              <el-form-item label="数据存储长度">-->
<!--                <el-col :span="5">-->
<!--                  <el-switch-->
<!--                      v-model="storage_lenSwitch"-->
<!--                      active-text="必选"-->
<!--                      inactive-text="可选">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--                <el-col :span="5">-->
<!--                  <el-form-item prop="storage_len">-->
<!--                    <el-input v-model="addForm.storage_len" style="width: 90px"></el-input> 位-->
<!--                  </el-form-item>-->
<!--                  <el-select v-model="addForm.storage_lenmethod"  placeholder="请选择治理函数" size="small" style="width: 150px; margin-left: 0px">-->
<!--                    <el-option-->
<!--                        v-for="item in Methodoptions"-->
<!--                        :key="item.id"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-col>-->

<!--              </el-form-item>-->

<!--              &lt;!&ndash;时延要求模块&ndash;&gt;-->
<!--              <el-form-item label="时延要求" >-->
<!--                <el-col :span="5">-->
<!--                  <el-switch-->
<!--                      v-model="timedelaySwitch"-->
<!--                      active-text="必选"-->
<!--                      inactive-text="可选">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--                <el-col :span="5">-->
<!--                  <el-form-item prop="timedelay">-->
<!--                    <el-input v-model="addForm.timedelay" style="width: 90px"></el-input> 秒-->
<!--                  </el-form-item>-->
<!--                  <el-select v-model="addForm.timedelaymethod"  placeholder="请选择治理函数" size="small" style="width: 150px; margin-left: 0px">-->
<!--                    <el-option-->
<!--                        v-for="item in Methodoptions"-->
<!--                        :key="item.id"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-col>-->

<!--              </el-form-item>-->

<!--              &lt;!&ndash;时序性要求模块&ndash;&gt;-->
<!--              <el-form-item label="时序性要求">-->
<!--                <el-col :span="5">-->
<!--                  <el-switch-->
<!--                      v-model="timesequenceSwitch"-->
<!--                      active-text="必选"-->
<!--                      inactive-text="可选">-->
<!--                  </el-switch>-->
<!--                </el-col>-->
<!--                <el-col :span="5">-->
<!--                  <el-form-item prop="timesequence">-->
<!--                    <el-input v-model="addForm.timesequence" style="width: 100px"></el-input>-->
<!--                  </el-form-item>-->
<!--                  <el-select v-model="addForm.timesequencemethod"  placeholder="请选择治理函数" size="small" style="width: 150px; margin-left: 0px">-->
<!--                    <el-option-->
<!--                        v-for="item in Methodoptions"-->
<!--                        :key="item.id"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id">-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                </el-col>-->

<!--              </el-form-item>-->
<!--            </el-col>-->

<!--          </el-row>-->




<!--        </el-tab-pane>-->

        <el-tab-pane label="确认" name="3">
          <el-form-item label="当前操作员" prop="currOperatorName" style="margin-bottom: 10px">
            <el-input style="width: 20%" class="small-input" v-model="addForm.currOperatorName" placeholder="管理员user"
                      disabled></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm">立即创建</el-button>
            <!--            <el-button @click="resetForm">重置</el-button>-->
          </el-form-item>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </el-dialog>
</template>

<script>
import { isPositiveNum, isTwoDecimal ,isSpecialCharacter} from '@/utils/validate'
export default {
  name: "addMeasurePointDialog",
  data() {
    var validateIsPositiveNum = (rule, input, callback) => {
      if (input === '' || input === null) {
        callback()
      } else {
        if (!isPositiveNum(input)) {
          callback(new Error('请输入正整数'))
        } else {
          callback()
        }
      }
    }

    var validateIsSpecialcharacter = (rule,input,callback) =>{
      if(isSpecialCharacter(input)) {
        callback(new Error('请不要输入除了“#”的特殊字符'))
      }
      else{
        callback();
      }
    }

    var validateIsTwoDecimal = (rule, input, callback) => {
      if(this.rangeSwitch && this.rationalityrangeSwitch)
      {
        if (!isTwoDecimal(input)) {
          callback(new Error('请输入最多两位小数'))
        } else {
          callback()
        }
      }else{
        callback()
      }
    }
    let that = this;
    return {
      addMeasurePointDialog: false,
      activeIndex: 0,
      options: [],
      typeOptions:[
        {
          value: 1,
          label: "boolean"
        },{
          value: 2,
          label: "float"
        },{
          value: 3,
          label: "double"
        },{
          value: 4,
          label: "int"
        },{
          value: 5,
          label: "string"
        }
      ],

      value: '',
      addForm: {
        measurePointName: '',
        remark: '',

        isExisted: '',
        isExistedmethod: '',
        type: '',
        typemethod: '',
        uprange: '',
        lowrange: '',
        rationalityuprange: '',
        rationalitylowrange: '',
        rationalitymethod: '',
        storage_len: '',
        storage_lenmethod: '',
        timedelay: '',
        timedelaymethod: '',
        timesequence: '',
        timesequencemethod: '',
        modifyrecord: '',
        modifyreason: '',
        version:'v1.0',
        currOperator: '',
        currOperatorName: ''
      },
      show: true,
      isNeedDataGovernment: true,
      isExistedSwitch: true,
      typeSwitch:true,
      rangeSwitch:true,
      rationalityrangeSwitch: true,
      storage_lenSwitch: true,
      timedelaySwitch: true,
      timesequenceSwitch: true,

      isExistedMethodshow: true,

      istimesequence:true,

      Methodoptions: [],

      form: {
        name: '',
        isExisted: '',
        isExistedmethod: '',
        type: '',
        typemethod: '',
        uprange: '',
        lowrange: '',
        rationalityuprange: '',
        rationalitylowrange: '',
        rationalitymethod: '',
        storage_len: '',
        storage_lenmethod: '',
        timedelay: '',
        timedelaymethod: '',
        timesequence: '',
        timesequencemethod: '',
        modifyrecord: '',
        modifyreason: '',
        version:'',
      },
      rules: {
        // parentList: [
        //   {required: true, message: '请选择附属设备', trigger: 'blur'}
        // ],
        measurePointName: [
          {required: true, message: '请输入数据项名称', trigger: 'blur'},
          // {validator:validateIsSpecialcharacter,trigger: 'blur'}
        ],
        // enabled: [
        //   {required: true, message: ' 请选择使用状态', trigger: 'blur'}
        // ],
        remark: [
          {required: true, message: '请输入数据项描述', trigger: 'blur'}
        ],
        type: [
          {required: this.typeSwitch, message: '请输入数据类型', trigger: 'blur'}
        ],
        lowrange: [
          {required: this.rangeSwitch, message: '请输入下阈值', trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        uprange: [
          {required: this.rangeSwitch, message: '请输入上阈值', trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        rationalitylowrange: [
          {required: this.rationalityrangeSwitch, message: '请输入数据合理范围下阈值',trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        rationalityuprange: [
          {required: this.rationalityrangeSwitch, message: '请输入数据合理范围上阈值',trigger: 'blur'},
          { validator: validateIsTwoDecimal, trigger: 'blur' }
        ],
        storage_len: [
          {required: this.storage_lenSwitch, message:'请输入数据存储长度', trigger:'blur'},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        timedelay: [
          {required: this.timedelaySwitch, message: '请输入时延要求',trigger: 'blur'},
          { validator: validateIsPositiveNum, trigger: 'blur' }
        ],
        timesequence: [
          {required: this.timesequenceSwitch, message: '请输入时序性要求',trigger: 'blur'}
        ]
      },
      props: {
        //级联选择器懒加载
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          console.log(level, node);
          if (level == 0) {
            that.getAllCoal((list1) => {
              let arr = list1.map((e) => ({ value: e.id, label: e.coal }));
              resolve(arr); // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            });
          }
          if (level == 1) {
            let { value } = node;
            console.log(value)
            that.getSystem(value, (list2) => {
              console.log(list2)
              if (list2[0] == null) {
                let arr = list2.map((e) => ({ value: 0, label: 0}));
                resolve(arr);
              } else {
                let arr = list2.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              }
            })
          }
          if (level == 2) {
            let { value } = node;
            console.log(value)
            that.getSubSystem(value, (list3) => {
              console.log(list3)
              if (list3[0] == null) {
                let arr = list3.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              } else {
                let arr = list3.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              }

            })
          }
          if (level == 3) {
            let { value } = node;
            console.log(value)
            that.getEquipment(value, (list4) => {
              console.log(list4)
              if (list4[0] == null) {
                let arr = list4.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              } else {
                let arr = list4.map((e) => ({ value: e.id, label: e.name}));
                resolve(arr);
              }

            })
          }
          if (level == 4) {
            let { value } = node;
            console.log(value)
            that.getSubEquipment(value, (list5) => {
              console.log(list5)
              if (list5[0] == null) {
                let arr = list5.map((e) => ({ value: e.id, label: e.name, leaf: true}));
                resolve(arr);
              } else {
                let arr = list5.map((e) => ({ value: e.id, label: e.name, leaf: true}));
                resolve(arr);
              }
            })
          }
        }
      }
      //         rules: {
      //             point: [
      //                 {required: true, message: '请输入数据项', trigger: 'blur'}
      //             ],
      //             isExisted: [
      //                 {required: true, message: '请选择数据是否存在', trigger: 'blur'},
      //             ],
      //             uprange: [
      //                 {required:true, message: '请输入上阈值', trigger: 'blur'},
      //             ],
      //             lowrange: [
      //                 {required: true, message: '请输入下阈值', trigger: 'blur'},
      //             ],
      //             rationalityuprange: [
      //                 {required: true, message: '请输入数据合理范围上阈值', trigger: 'blur'},
      //             ],
      //             rationalitylowrange: [
      //                 {required: true, message: '请输入数据合理范围下阈值', trigger: 'blur'},
      //             ],
      //             // storage_len: [
      //             //     {required: !form.storage_lenSwitch, message: '请输入数据存储长度', trigger: 'blur'},
      //             // ],
      //             timedelay: [
      //                 {required: true, message: '请选择时延要求', trigger: 'blur'},
      //             ],
      //             // timesequence: [
      //             //     {required: true, message: '请输入时序性要求',trigger: 'blur'},
      //             //     // {type: 'number', message:'请输入数字',trigger: 'blur'},
      //
      //             // ],
      //         }
    }
  },
  mounted() {
    this.addForm.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.addForm.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;
    this.getAllMethod()
  },
  methods: {
    init() {
      this.addMeasurePointDialog = true;
    },
    async getAllCoal(callback) {
      this.getRequest('/dataList/getAllCoal/').then(resp=>{
        if (resp.data){
          callback(resp.data);
          console.log(resp.data)
        }
      });
    },
    async getSystem(coalId, callback) {
      this.getRequest('/coalManage/getSystemInfoByCoalId?coalId=' + coalId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getSubSystem(systemId, callback) {
      this.getRequest('/coalManage/getSubSystemInfoBySystemId?systemId=' + systemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getEquipment(subSystemId, callback) {
      this.getRequest('/coalManage/getEquipmentInfoBySubSystemId?subSystemId=' + subSystemId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    async getSubEquipment(equipmentId, callback) {
      this.getRequest('/coalManage/getSubEquipmentInfoByEquipmentId?equipmentId=' + equipmentId).then((resp) => {
        if (resp.data) {
          console.log(resp.data)
          callback(resp.data);
        }
      })
    },
    addMeasurePoint() {
      this.$confirm(`确定进行创建[${this.addForm.measurePointName}]操作?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postRequest("/measurePointManage/insertMeasurePointInfoByEquipmentId", this.addForm).then((resp) => {
          if (resp.data) {
            this.$emit('update', true)
            this.addMeasurePointDialog = false
            this.activeIndex = "0"
            this.show = true
            this.isNeedDataGovernment = true
            this.isExistedSwitch = true
            this.typeSwitch = true
            this.rangeSwitch = true
            this.rationalityrangeSwitch = true
            this.storage_lenSwitch = true
            this.timedelaySwitch = true
            this.timesequenceSwitch = true
            this.isExistedMethodshow = true
            this.istimesequence = true
            this.$refs.addForm.resetFields()
          }
        })
      }).catch(() => {})
    },
    submitForm() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          console.log(this.addForm)
          this.addForm.subEquipmentId = this.global.sub_equipment_id
          // this.addForm.subEquipmentId = this.$refs["cascade"].getCheckedNodes()[0].value
          this.addMeasurePoint()
          // this.closeDialog()
        } else {
          return false;
        }
      })
    },
    cancel(){
      this.form={};
      this.addMPdialogVisible = false;
    },
    // 展示对话框
    editMeasurePoint() { //保存数据项数据
      console.log(this.isExistedSwitch);
      if(this.isExistedSwitch == true) {this.form.isExisted="1";}
      else {this.form.isExisted="0";}
      this.form.version="v1.0";  //新增加的都是第一版

      this.postRequest('/dataQualityMeasurePoint/editMeasurePoint', this.form).then(resp => {
        if (resp.data) {
          this.addMPdialogVisible = false;
        }
      });
    },
    getAllMethod() {
      this.getRequest('/dataQualityMeasurePoint/getAllMethod').then( resp => {
        if(resp.data) {
          this.Methodoptions = resp.data;
          console.log(resp.data);
        }
      })
    },
    closeDialog() {
      this.addMeasurePointDialog = false;
      this.$refs["addForm"].resetFields();
    },
    isNeedDataGovernmentChange(isNeedDataGovernment){
      if(isNeedDataGovernment){
        this.isExistedSwitch=true;
        this.typeSwitch=true;
        this.rangeSwitch=true;
        this.rationalityrangeSwitch=true;
        this.storage_lenSwitch=true;
        this.timedelaySwitch=true;
        this.timesequenceSwitch=true;
      }else{
        this.isExistedSwitch=false;
        this.typeSwitch=false;
        this.rangeSwitch=false;
        this.rationalityrangeSwitch=false;
        this.storage_lenSwitch=false;
        this.timedelaySwitch=false;
        this.timesequenceSwitch=false;
      }
      console.log(this.rangeSwitch)
    }
  }
}
</script>

<style scoped>

</style>
